<template>
  <div>
    <!-- Will Add it Later -->
  </div>
</template>

<script>
export default {
  name: 'Forgot'
};
</script>

<style scoped>

</style>
